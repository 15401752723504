/**
 * Tabler.js includes Bootstrap 5
 */
require('@tabler/core/src/js/tabler.js');

require('./bootstrap');

import Litepicker from 'litepicker';
import IMask from 'imask';
import Sortable from 'sortablejs';

const sortables = []
const litePickers = []
const iMasks = []

window.csAPI_initFormHelpers = () => {

    while (litePickers.length > 0) {
        let _ = litePickers.pop();
        _.destroy()
    }

    while (iMasks.length > 0) {
        let _ = iMasks.pop();
        _.destroy()
    }

    /**
     * https://github.com/SortableJS/Sortable
     */
    const sortableContainers = [].slice.call(document.querySelectorAll('[data-sortable]'));
    sortables.push(...sortableContainers.map(function (el) {
        return new Sortable(el, {
            animation: 150,
            ghostClass: 'blue-background-class',
            onEnd: (e) => {
                Livewire.emit(
                    `${el.dataset.sortable}Sorted`,
                    Array.from(document.querySelectorAll('[data-sortable] tr')).map(el => el.dataset.key)
                )
            }
        })
    }));

    const datePickers = [].slice.call(document.querySelectorAll('[data-datepicker]'));
    litePickers.push(...datePickers.map(function (el) {
        el.readOnly = true
        return new Litepicker({
            element: el,
            format: 'DD.MM.YYYY',
            setup: (picker) => {
                picker.on('selected', () => {
                    el.dispatchEvent(new InputEvent('input'))
                    picker.hide()
                })
            },
        })
    }));

    const dateRangePickers = [].slice.call(document.querySelectorAll('[data-datepicker-range]'));
    dateRangePickers.map(function (el) {
        el.readOnly = true
        return new Litepicker({
            element: el,
            elementEnd: document.getElementById(el.dataset.datepickerRange),
            format: 'DD.MM.YYYY',
            autoApply: true,
            singleMode: false,
            resetButton: true,
            setup: (picker) => {
                picker.on('selected', () => {
                    el.dispatchEvent(new InputEvent('input'))
                })
                picker.on('show', () => {
                    let date = picker.getDate();
                    if (date) {
                        date.setMonth(date.getMonth() - 1);
                        picker.gotoDate(date);
                    }
                })
            },
        })
    });

    const maskElementList = [].slice.call(document.querySelectorAll('[data-imask]'));
    iMasks.push(...maskElementList.map(function (el) {
        let options = {
            mask: el.dataset.imask,
        }

        if (el.dataset.imask[0] === ':') {
            const config = el.dataset.imask.substring(1).split(',')
            switch (config[0]) {
                case 'date':
                    options = {
                        ...options,
                        mask: Date,
                    }
                    break
                case 'decimal':
                    options = {
                        ...options,
                        mask: Number,
                        min: config[1] ?? 0,
                        max: config[2] ?? 999999999,
                        radix: '.',
                        mapToRadix: [','],
                        normalizeZeros: true,
                        padFractionalZeros: false,
                        signed: false,
                        scale: config[3] ?? 2,
                    }
                    break
                default:
                    options = {
                        ...options,
                        mask: el.dataset.imask,
                    }
            }
        }

        return new IMask(el, options)
    }))
}
window.csAPI_initFormHelpers()

const tabElList = [].slice.call(document.querySelectorAll('button[data-bs-toggle="tab"]'));
tabElList.map(function (tabEl) {
    tabEl.addEventListener('shown.bs.tab', function (event) {
        history.replaceState(
            {tab: event.target.dataset.bsTarget},
            event.target.dataset.innerText,
            event.target.dataset.bsTarget
        );
    })
})

const tabSelector = location.hash
    ? `button[data-bs-toggle="tab"][data-bs-target="${location.hash}"]`
    : 'button[data-bs-toggle="tab"][data-bs-target]:first-child'

const preselectedTabEL = document.querySelector(tabSelector)

if (preselectedTabEL && location.hash) {
    const preselectedTab = new bootstrap.Tab(preselectedTabEL)
    preselectedTab?.show()
    window.scrollTo(0, 0)
    setTimeout(function () {
        window.scrollTo(0, 0)
    }, 1)
}

// const bsAlert = document.createElement('div')
// bsAlert.classList.add('alert alert-important alert-success alert-dismissible')
// bsAlert.setAttribute('role', 'alert')
// bsAlert.innerHTML = '<div class="d-flex">\n' +
//     '    <div>\n' +
//     '      Your account has been saved!\n' +
//     '    </div>\n' +
//     '  </div>\n' +
//     '  <a class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"></a>'
// document.body.append(bsAlert)
// window.addEventListener('bsAlert.show', event => {
//
// })

window.csAPI_goTrLink = (el, e) => {
    if (e.target.tagName.toUpperCase() === 'A') {
        return
    }

    const anchor = el.querySelector('a.tr-link')
    if (anchor) {
        document.location.href = anchor.href
    }
}

window.element_auto_grow = (element) => {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight) + "px";
}

window.autoGrowAllTextarea = () => {
    document.querySelectorAll('textarea').forEach(el => {
        el.style.height = el.setAttribute('style', 'height: ' + el.scrollHeight + 'px');
        el.classList.add('auto');
        el.addEventListener('input', e => {
            el.style.height = 'auto';
            el.style.height = (el.scrollHeight) + 'px';
        });
    });
}
